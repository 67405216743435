import { useCallback, useEffect, useRef } from 'react';
import { left, tryCatch, TaskEither } from 'fp-ts/TaskEither';

export function useRecaptchaToken() {
  const loaded = useRef<boolean>(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!window.grecaptcha) {
        return;
      }

      clearInterval(timer);
      grecaptcha.ready(() => (loaded.current = true));
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const getToken = useCallback((): TaskEither<string, string> => {
    if (!loaded.current) {
      return left('reCAPTCHA not initialized!');
    }

    // TODO: Log error with AppInsight once it is implemented
    return tryCatch(
      () =>
        window.grecaptcha.execute(
          process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string,
          {
            action: 'submit',
          }
        ),
      () => 'reCAPTCHA failed to fetch token'
    );
  }, []);

  return getToken;
}
