import { SEOMetadata } from '~/components/SEOMetadata';

const AuthLayout = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <>
      <SEOMetadata items={[]} />
      {children}
    </>
  );
};

export default AuthLayout;
