import { pageLinks } from '../../../lib/prefetch';
import { Login } from '../../../modules/account/Authentication/LoginPage';
import * as TE from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import { withFeatureFlags } from '~/lib/server/features';
import { withRevalidation } from '~/lib/server/revalidation';
import { NotFoundResult, compose } from '~/lib/server/staticProps';
import { withTranslations } from '~/lib/server/translations';
import AuthLayout from '~/modules/account/Authentication/AuthLayout';

const composedStaticProps = compose(
  withTranslations(['bridgeStatus', 'common']),
  withRevalidation(),
  withFeatureFlags
);

export const getStaticProps = composedStaticProps(({ locale }) =>
  pipe(
    locale as string,
    pageLinks,
    TE.fromTask,
    TE.bimap(
      () => ({ notFound: true } as NotFoundResult),
      (dehydratedState) => ({
        props: { dehydratedState },
      })
    )
  )
);

Login.getLayout = function getLayout(page) {
  return <AuthLayout>{page}</AuthLayout>;
};

export default Login;
