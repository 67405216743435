import { useRouter } from 'next/router';
import { useCallback } from 'react';

export function useRedirect() {
  const { push } = useRouter();

  const redirect = useCallback(
    (loginRedirect?: string): Promise<void> => {
      return push(
        loginRedirect ?? '/account/dashboard'
      ) as unknown as Promise<void>;
    },
    [push]
  );

  return redirect;
}
