import { AxiosError, AxiosInstance } from 'axios';
import { lookup } from 'fp-ts/lib/Record';
import { map } from 'fp-ts/lib/Option';
import { isString } from 'fp-ts/lib/string';
import { pipe } from 'fp-ts/lib/function';
import { backend, backendRequest } from '@oresundsbron/api';
import { api } from '../../../../lib/api/client';
import { chain } from 'fp-ts/lib/TaskEither';

export type LoginError = 'user' | 'internal';
export const toLoginError = (reason: unknown): LoginError => {
  if (!reason || isString(reason)) {
    return 'internal';
  }

  const error = reason as AxiosError;
  if (Number(error.code) >= 400 && Number(error.code) < 500) {
    return 'user';
  }

  return 'internal';
};

type ErrorHandler = () => string;

export const matchError =
  <K extends string>(actions: Record<K, ErrorHandler>) =>
  (actionKey: K) =>
    pipe(
      actions,
      lookup(actionKey),
      map((action) => action())
    );
