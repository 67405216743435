import {
  Box,
  Card,
  Grid,
  PageWrapper,
  Typography,
} from '@oresundsbron/bridge-ui';
import { FormStoreProvider } from '@oresundsbron/use-form';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { LoginForm } from './Login';
import { useRedirect } from './useRedirect';
import { BackendQueryKeys, useTypeSafePrefetch } from '@oresundsbron/api';
import { useAuth } from '~/stores/auth';
import { NextPageWithLayout } from '~/pages/_app';

export const Login: NextPageWithLayout = ({}) => {
  const { t } = useTranslation('common');
  const { push, query } = useRouter();
  const redirect = useRedirect();
  const { isAuthenticated } = useAuth();
  const prefetchBankIDOrder = useTypeSafePrefetch(
    BackendQueryKeys.BankIDOrder(),
    (q) => q.Auth.bankID.order
  );

  const loginRedirect = useCallback(
    () => redirect(query.loginRedirectUrl as unknown as string),
    [query, redirect]
  );

  useEffect(() => {
    if (isAuthenticated) {
      loginRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormStoreProvider>
      <PageWrapper>
        <Grid className="col-start-2 py-8">
          <Box className="col-span-full sm:col-span-6 sm:col-start-2 md:col-span-6 md:col-start-4">
            <Card.Root className="mx-auto max-w-md">
              <Card.Header>
                <Typography as="h1" intent="headline" className="text-center">
                  {t('authentication.login.title')}
                </Typography>
              </Card.Header>
              <Card.Content>
                <Typography className="mb-4">
                  <Trans ns="common" i18nKey="authentication.login.description">
                    <Typography as="span" />
                  </Trans>
                </Typography>
                <LoginForm
                  onLoginSuccess={loginRedirect}
                  onGoToEIdentity={(eID) => {
                    switch (eID) {
                      case 'BankID':
                        push('/account/login/bankid');
                    }
                  }}
                  mouseOverEIdentity={async () => await prefetchBankIDOrder()}
                  onGoToForgotPassword={() => push('/account/login/forgot')}
                />
              </Card.Content>
            </Card.Root>
          </Box>
        </Grid>
      </PageWrapper>
    </FormStoreProvider>
  );
};
